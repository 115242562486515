* {
  box-sizing: border-box;
  &:before,
  &:after {
    @extend *;
  }
}
html {
  -webkit-tap-highlight-color: transparent;
}
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
img {
  vertical-align: middle;
}
body {
  background: $text-color;
  color: $off-white;
  color: rgba($off-white, 0.9);
  font-family: $base-font-family;
  font-size: $base-font-size;
}
section {
  overflow: hidden;
}
::selection {
  background: $brand-color;
  color: $off-white;
}
::-moz-selection {
  background: $brand-color;
  color: $off-white;
}

@import 'hero', 'layout';
