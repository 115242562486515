@charset "utf-8";
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300|Rubik:700');

// Define defaults for each variable.
// Base font size is 16px via browser defaults
// so use REMs ie: 48px = 3rem --> (48/16 = 3)
$base-font-family: 'Open Sans', sans-serif !default;
$title-font-family: 'Rubik', sans-serif !default;
$base-font-weight: 300 !default;
$base-font-size: 16px !default;

$text-color: #010e12 !default;
$off-white: #fdfdfd !default;
$brand-color: #00a3ff !default;
$brand-alt: #f08a4b !default;

// Width of the content area
$content-width: 800px !default;

$on-palm: 600px !default;
$on-tablet: 767px !default;
$on-laptop: 800px !default;

// Use media queries like this:
// @include mq($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin mq($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

// Import partials.
@import 'loader';
@import 'normalize';
@import 'base';
