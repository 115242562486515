%display_block {
  display: block;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

.wrapper {
  position: absolute;
  overflow: hidden;
  display: table-cell;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.scene {
  @extend %display_block;
  position: relative;
  overflow: hidden;
}
.layer {
  @extend %display_block;
  position: absolute;
  div {
    transform: translate3d(0, 0, 0);
    transform-style: preserve-3d;
    backface-visibility: hidden;
  }
}

.background {
  background: linear-gradient(
      -45deg,
      rgba(1, 1, 5, 0.6) 0%,
      rgba(1, 1, 5, 0.8) 100%
    ),
    url('../images/ocean_container.jpg') no-repeat 50% 100%;
  bottom: -1.25rem;
  background-size: cover;
  background-position: center 75%;
  position: absolute;
  width: 110%;
  left: -5%;
  top: -5%;
}
