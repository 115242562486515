@keyframes loader-anim {
  0% {
    left: -102px;
  }
  100% {
    left: 102px;
  }
}
@keyframes blink-text {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0.5;
  }
  40% {
    opacity: 0.2;
  }
  60% {
    opacity: 0.7;
  }
  80% {
    opacity: 0.1;
  }
  90% {
    opacity: 0.4;
  }
}

.preloader {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: $text-color;
  text-align: center;
  &__loading-title {
    font-size: 0.8rem;
    font-weight: $base-font-weight;
    margin-top: 1.25rem;
    animation: blink-text 1s infinite;
  }
  &__loading-logo {
    max-width: 6rem;
  }
}

.loading {
  overflow: hidden;
  position: absolute;
  top: 50%;
  margin-top: -6.25rem;
  left: 50%;
  margin-left: -3.125rem;
  width: 6.25rem;
  height: 12.5rem;
}
.progress {
  display: block;
  position: absolute;
  bottom: 3.125rem;
  overflow: hidden;
  width: 6.25rem;
  height: 1px;
  left: -6.375rem;
  background-color: $brand-color;
  animation: loader-anim 1s 0s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
